<template>
  <div class="pr-2 pl-2 mb-4">
    <div
      class="row"
      :style="'background:' + document.settings.color1 + '; color:#FFFFFF'"
    >
      <div class="th col-md-6 col-sm-3">Désignation</div>
      <div class="th col-md-2 col-sm-3">Prix unitaire</div>
      <div class="th col-md-2 col-sm-3">Quantité</div>
      <div class="th col-md-2 col-sm-3">Montant</div>
    </div>
    <div
      class="row"
      v-for="(line, index) in document.lines"
      :key="index"
      :style="index % 2 == 0 ? 'background:' + document.settings.color2 : ''"
    >
      <div class="td col-md-6">
        <div class="input-group">
          <div class="btn-delete">
            <button class="btn btn-sm" @click="deleteLine(index)">
              <span class="fa fa-trash text-danger"></span>
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="document.lines[index].name"
            placeholder="Description du produit ou du service"
          />
        </div>
      </div>
      <div class="td col-md-2">
        <input
          type="number"
          class="form-control"
          v-model="document.lines[index].unit_cost"
          placeholder="Prix"
          min="0"
        />
      </div>
      <div class="td col-md-2">
        <input
          type="number"
          class="form-control"
          v-model="document.lines[index].quantity"
          placeholder="Quantité"
          min="0"
        />
      </div>
      <div class="td col-md-2">
        <input
          type="text"
          class="form-control text-right"
          :value="
            (document.lines[index].quantity * document.lines[index].unit_cost)
              | number
          "
          disabled
        />
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: ["document", "labels"],
  data() {
    return {};
  },
  methods: {
    deleteLine(index) {
      this.document.lines = _.remove(this.document.lines, function (o, i) {
        return i != index;
      });
    },
  },
};
</script>

<style scoped>
.form-control {
  font-size: 0.8rem;
}
.td {
  padding: 5px 10px;
}
.th {
  padding: 10px;
  font-weight: bold;
  border-bottom: 5px solid white;
}
.btn-delete {
  margin-top: 3px;
  text-align: left;
}
</style>
