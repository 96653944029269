<template>
  <div class="input-group color-picker" ref="colorpicker">
    <input
      type="text"
      class="form-control"
      v-model="colorValue"
      @focus="showPicker()"
      @input="updateFromInput"
    />
    <div class="input-group-append color-picker-container">
      <div
        class="input-group-text"
        :style="'width:30px; background-color: ' + colorValue"
        @click="togglePicker()"
      ></div>
    </div>
    <sketch-picker
      :value="colorValue"
      @input="updateFromPicker"
      v-if="displayPicker"
    ></sketch-picker>
  </div>
</template>

<script>
import { Sketch } from "vue-color";

export default {
  components: {
    "sketch-picker": Sketch,
  },
  props: ["color"],
  data() {
    return {
      colors: {
        hex: this.color,
      },
      colorValue: this.color,
      displayPicker: false,
    };
  },
  mounted() {
    this.setColor(this.colorValue);

    // console.log("color", this.colorValue);
  },
  methods: {
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit("input", val);
      }
    },
  },
};
</script>
