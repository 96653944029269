<template>
  <div class="row">
    <div class="col-md-9">
      <div class="bg-white p-3 border rounded">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Désignation</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Facture"
                  v-model="document.title"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Référence</label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Référence"
                  v-model="document.number"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Date</label>
              <div class="col-sm-8">
                <b-form-datepicker
                  v-model="document.issue_date"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"> Echéance</label>
              <div class="col-sm-8">
                <b-form-datepicker
                  v-model="document.due_date"
                ></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"> Vous </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Votre raison sociale"
                  v-model="document.company_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Votre adresse</label>
              <div class="col-sm-8">
                <textarea
                  class="form-control"
                  placeholder="Votre adresse"
                  v-model="document.company_address"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"> Votre client </label>
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Raison sociale du client"
                  v-model="document.client_name"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Son adresse </label>
              <div class="col-sm-8">
                <textarea
                  class="form-control"
                  placeholder="Adresse du client"
                  v-model="document.client_address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <lines :document="document" :labels="labels"></lines>

        <div class="row">
          <div class="col-md">
            <button type="button" class="btn btn-success mb-4" @click="addLine">
              + ajouter une ligne
            </button>

            <div class="form-group">
              <label for="exampleFormControlInput1">Conditions</label>
              <textarea
                class="form-control"
                placeholder="Termes et conditions - frais de retard, modes de paiement, livraison..."
                v-model="document.terms"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Notes</label>
              <textarea
                class="form-control"
                placeholder="Remarques, toute information pertinente non déjà couverte ..."
                v-model="document.notes"
              ></textarea>
            </div>
          </div>
          <div class="col-md">
            <div class="row mb-2">
              <div class="col-md-6">Sous-Total</div>
              <div class="col-md-6">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-right"
                    :value="total.subtotal | number"
                    disabled
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      {{ document.currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">Remise</div>
              <div class="col-md-6">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="document.discount_value"
                    min="0"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">Taxe</div>
              <div class="col-md-6">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    v-model="document.tax_value"
                    min="0"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">Total</div>
              <div class="col-md-6">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-right"
                    :value="total.total_incltax | number"
                    disabled
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      {{ document.currency }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="exampleFormControlInput1">Pied de page</label>
              <textarea
                class="form-control"
                placeholder="Saissisez votre raison sociale, vos coordonnées, votre RC, ICE ..."
                v-model="document.footer"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- <actions :document="document"></actions> -->

        <!-- <pre>
        {{ document }}
        </pre> -->
      </div>
    </div>
    <div class="col-md-3 pt-4">
      <sidebar :document="document"></sidebar>
    </div>
  </div>
</template>

<script>
import Lines from "./lines";
import Sidebar from "./sidebar";
import Actions from "./actions";
import moment from "moment";

export default {
  components: {
    Sidebar,
    Lines,
    Actions,
  },
  props: ["invoice", "token"],
  data() {
    return {
      labels: {
        from: "From",
        to: "To",
        quantity: "Quantity",
      },
      document: {
        id: null,
        title: "Facture",
        number: "",
        company_logo: null,
        company_name: null,
        company_address: null,
        client_name: null,
        client_address: null,
        issue_date: moment().format("YYYY-MM-DD"),
        due_date: moment().format("YYYY-MM-DD"),
        lines: [],
        currency: "MAD",
        subtotal: 0,
        tax_value: 0,
        tax_amount: 0,
        discount_value: 0,
        discount_amount: 0,
        total_excltax: 0,
        total_incltax: 0,
        notes: null,
        terms: null,
        footer: null,
        settings: {
          color1: "#008ED5",
          color2: "#F9F9F9",
        },
      },
    };
  },
  mounted() {
    this.addLine();

    if (this.invoice) {
      this.document = this.invoice;
    }

    let token = localStorage.getItem("token");
    if (!token) {
      localStorage.setItem("token", token);
    }
  },
  methods: {
    addLine() {
      this.document.lines.push({
        name: null,
        quantity: null,
        unit_cost: null,
      });
    },
  },
  computed: {
    total: function () {
      let subtotal = 0;

      this.document.lines.forEach((line) => {
        subtotal += line.quantity * line.unit_cost;
      });

      let discount_amount = subtotal * (this.document.discount_value / 100);
      let total_excltax = subtotal - discount_amount;
      let tax_amount = (total_excltax * this.document.tax_value) / 100;

      let total_incltax = total_excltax + tax_amount;

      let result = {
        subtotal: subtotal,
        tax_amount: tax_amount,
        discount_amount: discount_amount,
        total_incltax: total_incltax,
        total_excltax: total_excltax,
      };

      this.document.subtotal = subtotal;
      this.document.total_incltax = total_incltax;
      this.document.total_excltax = total_excltax;
      this.document.tax_amount = tax_amount;
      this.document.discount_amount = discount_amount;

      return result;
    },
  },
};
</script>

<style scoped>
.col-form-label {
  font-size: 0.8rem;
}
.form-control {
  font-size: 0.8rem;
}
.table th,
.table td {
  padding: 5px !important;
}
.input-group-text {
  font-size: 0.8rem;
}
.form-control-plaintext {
  text-align: right;
}
</style>
