<template>
  <div>
    <button
      type="button"
      class="btn btn-block btn-success btn-lg"
      @click="save()"
    >
      <span class="fa fa-save"></span> Enregistrer
    </button>
    <!-- <button
      v-if="document.id"
      type="button"
      class="btn btn-block btn-secondary btn-lg"
      @click="preview"
    >
      <span class="fa fa-file"></span> Aperçu
    </button> -->
    <button
      v-if="document.id"
      type="button"
      class="btn btn-block btn-primary btn-lg"
      @click="download"
    >
      <span class="fa fa-download"></span> Télécharger
    </button>
    <!-- <button
      v-if="document.id"
      type="button"
      class="btn btn-block btn-primary btn-lg"
      @click="send"
    >
      <span class="fa fa-rocket"></span> Envoyer
    </button> -->

    <hr />

    <div class="form-group">
      <label class="col-form-label">Logo</label>
      <div class="input-group">
        <logo :document="document"></logo>
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label">Devise</label>
      <div class="input-group">
        <v-select
          :options="currenciesOptions"
          v-model="document.currency"
          :reduce="(currency) => currency.code"
        ></v-select>
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label">Couleur principale </label>
      <div>
        <div class="input-group">
          <color-picker
            v-model="document.settings.color1"
            :color="document.settings.color1"
          ></color-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label">Couleur secondaire</label>
      <div>
        <div class="input-group">
          <color-picker
            v-model="document.settings.color2"
            :color="document.settings.color2"
          ></color-picker>
        </div>
      </div>
    </div>
    <p>
      <a class="btn btn-block btn-dark" :href="app_url + '/documents'">
        Mes factures
        <span class="badge badge-light">{{ documents }}</span>
      </a>
    </p>
  </div>
</template>

<script>
import config from "../../services/api";
import { app_url, api_url } from "../../services/environment";

import _ from "lodash";
import ColorPicker from "./colorpicker";
import Logo from "./logo";

const token = localStorage.getItem("token");

export default {
  props: ["document"],
  components: {
    ColorPicker,
    Logo,
  },
  data() {
    return {
      currenciesOptions: [],
      api_url,
      app_url,
      documents: 0,
    };
  },
  mounted() {
    let currencies = require("./currencies");

    _.forEach(currencies, (o) => {
      this.currenciesOptions.push({
        code: o.code,
        label: o.code + " - " + o.label,
      });
    });
    _.sortBy(this.currenciesOptions, ["label"]);

    this.documents = this.count();
  },
  methods: {
    preview() {
      var url = app_url + "/documents/" + this.document.id;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    count() {
      if (token) {
        axios.get(api_url + "/documents/count", config).then((response) => {
          if (response.status == 200) {
            console.log(response);
            this.documents = response.data;
          }
        });
      }
    },
    download() {
      var url = api_url + "/documents/" + this.document.id + "/download";
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    send() {
      var url = app_url + "/documents/" + this.document.id + "/send";
      var a = document.createElement("a");
      a.href = url;
      a.click();
    },
    save() {
      if (!token) {
        this.$toastr.e(
          "Veuillez créer un compte pour pouvoir enregister vos factures."
        );
        return;
      }
      if (this.document.id == undefined) {
        axios
          .post(api_url + "/documents", this.document, config)
          .then((response) => {
            if (response.status == 201) {
              this.$toastr.s("Invoice saved ");
              window.location.href =
                app_url + "/documents/" + response.data.id + "/edit";
            }
          })
          .catch((error) => {
            let errors = _.values(error.response.data);
            errors = _.join(errors, "<br/>");
            this.$toastr.e(errors);
          });
      } else {
        axios
          .put(
            api_url + "/documents/" + this.document.id,
            this.document,
            config
          )
          .then((response) => {
            if (response.status == 200) {
              this.$toastr.s("Invoice updated ");
            }
          })
          .catch((error) => {
            let errors = _.values(error.response.data);
            errors = _.join(errors, "<br/>");
            this.$toastr.e(
              "Une erreur est survenue. Impossible d'enregistrer les modifications."
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.col-form-label {
  font-size: 0.8rem;
}
.form-control {
  font-size: 0.8rem;
}
</style>
