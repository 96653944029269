<template>
  <div>
    <img v-if="url" :src="url" class="img-thumbnail mb-2" />
    <img
      v-if="!url && document.file && document.file.url"
      :src="document.file.url"
      class="img-thumbnail mb-2"
    />
    <b-form-file
      v-model="file"
      id="file"
      @change="preview"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
    ></b-form-file>
  </div>
</template>

<script>
import { api_url } from "../../services/environment";

const token = localStorage.getItem("token");

let config = {
  headers: {
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  },
};

export default {
  props: ["document"],
  data() {
    return {
      url: null,
      file: null,
    };
  },
  methods: {
    preview() {
      var file = document.querySelector("#file");
      file = file.files[0];

      if (file) {
        this.url = URL.createObjectURL(file);
        this.upload();
      }
    },
    upload() {
      var formData = new FormData();
      var file = document.querySelector("#file");
      file = file.files[0];

      if (file) {
        formData.append("file", file);
        axios.post(api_url + "/files", formData, config).then((response) => {
          if (response.status == 201) {
            this.document.company_logo = response.data.id;
            this.url = URL.createObjectURL(file);
            this.$toastr.s("File uploaded ");
          }
        });
      }
    },
  },
};
</script>
