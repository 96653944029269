<template>
  <div class="page bg-white p-3 border rounded">
    <h2>Bienvenue sur Manageo Lite !!</h2>

    <p>
      Créez des factures personnalisées pour votre marque ou entreprise en
      quelques secondes. Pour enregistrer vos factures, les dupliquer ou les
      télécharger au format PDF, il vous suffit de créer un compte et le tour
      est joué.
    </p>
    <p>
      Saviez-vous que Manageo Lite vous permet de générer gratuitement un nombre
      illimité de factures ?
    </p>

    <button @click="start()" class="btn btn-primary">C’est parti !</button>
    <a class="btn btn-warning" href="/comment-ca-marche">Comment ça marche ?</a>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    let clicked = localStorage.getItem("start");
    if (clicked) {
      $(".page").css("display", "none");
    }
  },
  methods: {
    start() {
      localStorage.setItem("start", true);
      $(".page").css("display", "none");
    },
  },
};
</script>

<style scoped>
</style>
