<template>
  <div class="p-4">
    <div v-if="isLoading" class="spinner">
      <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else>
      <table
        class="table table-striped d-none d-xs-none d-sm-none d-md-block"
        v-if="documents.length"
      >
        <thead>
          <tr>
            <th>Référence</th>
            <th>From</th>
            <th>Client</th>
            <th>Date</th>
            <th class="text-right">Total HT</th>
            <th class="text-right">Total TTC</th>
            <th>Currency</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.id">
            <td>
              <a :href="app_url + '/documents/' + document.id">
                {{ document.number }}
              </a>
            </td>
            <td>{{ document.company_name }}</td>
            <td>{{ document.client_name }}</td>
            <td>
              {{ document.issue_date | date }}
            </td>
            <td class="text-right">
              {{ document.total_excltax | number }}
            </td>
            <td class="text-right">
              {{ document.total_incltax | number }}
            </td>
            <td>{{ document.currency }}</td>
            <td class="text-right">
              <a
                :href="app_url + '/documents/' + document.id + '/edit'"
                class="btn btn-action btn-warning"
                data-toggle="tooltip"
                data-placement="top"
                title="Modifier"
              >
                <span class="fa fa-edit"></span>
              </a>
              <!-- <a
                :href="app_url + '/documents/' + document.id"
                target="_blank"
                class="btn btn-success"
                >
                <span class="fa fa-eye"></span>
                </a> -->
              <a
                @click="duplicate(document)"
                class="btn btn-action btn-secondary"
                data-toggle="tooltip"
                data-placement="top"
                title="Dupliquer"
              >
                <span class="fa fa-copy"></span>
              </a>
              <a
                :href="api_url + '/documents/' + document.id + '/pdf'"
                target="_blank"
                class="btn btn-action btn-info"
                data-toggle="tooltip"
                data-placement="top"
                title="Télécharger"
              >
                <span class="fa fa-file"></span>
              </a>
              <a
                @click="deleteInvoice(document)"
                class="btn btn-action btn-danger"
                data-toggle="tooltip"
                data-placement="top"
                title="Supprimer"
              >
                <span class="fa fa-trash"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row" v-if="documents.length">
        <div
          class="col-sm-6 col-xs-12 d-block d-md-none"
          v-for="document in documents"
          :key="document.id"
        >
          <div class="document">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">
                  <a
                    :href="api_url + '/documents/' + document.id + '/download'"
                    target="_blank"
                  >
                    {{ document.number }}
                  </a>
                </h4>
                <div class="card-text">
                  <div>
                    {{ document.client_name }}
                  </div>
                  <div>
                    {{ document.issue_date | date }}
                  </div>
                  <div class="text-right">
                    <strong>
                      {{ document.total_incltax | number }}
                      {{ document.currency }}
                    </strong>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <a
                    :href="app_url + '/documents/' + document.id + '/edit'"
                    class="btn btn-action btn-warning"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Modifier"
                  >
                    <span class="fa fa-edit"></span>
                  </a>
                  <!-- <a
                :href="app_url + '/documents/' + document.id"
                target="_blank"
                class="btn btn-success"
                >
                <span class="fa fa-eye"></span>
                </a> -->
                  <a
                    @click="duplicate(document)"
                    class="btn btn-action btn-secondary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Dupliquer"
                  >
                    <span class="fa fa-copy"></span>
                  </a>
                  <a
                    :href="api_url + '/documents/' + document.id + '/download'"
                    target="_blank"
                    class="btn btn-action btn-info"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Télécharger"
                  >
                    <span class="fa fa-file"></span>
                  </a>
                  <a
                    @click="deleteInvoice(document)"
                    class="btn btn-action btn-danger"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Supprimer"
                  >
                    <span class="fa fa-trash"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!documents.length" class="text-center h1">
        <p>Aucune facture enregistrée.</p>
        <p>
          <a class="btn btn-lg btn btn-primary" :href="app_url">
            Créer une facture
          </a>
        </p>
      </div>
    </div>

    <b-modal
      id="delete"
      title="Êtes-vous sûr de supprimer cette facture ?"
      @ok="handleDelete"
      v-if="document"
    >
      <p class="my-4">
        Vous êtes sur le point de supprimer la facture {{ document.number }} ?
      </p>
    </b-modal>

    <b-modal
      id="duplicate"
      title="Êtes-vous sûr de dupliquer cette facture ?"
      @ok="handleDuplicate"
      v-if="document"
    >
      <p class="my-4">
        Vous êtes sur le point de dupliquer la facture {{ document.number }} ?
      </p>
    </b-modal>
  </div>
</template>

<script>
import config from "../../services/api";
import { app_url, api_url } from "../../services/environment";

const token = localStorage.getItem("token");

export default {
  data() {
    return {
      app_url: app_url,
      api_url: api_url,
      documents: [],
      isLoading: true,
      document: null,
      showDeleteModal: false,
    };
  },
  mounted() {
    if (token) {
      this.getDocuments();
    } else {
      this.isLoading = false;
    }
  },
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  methods: {
    getDocuments() {
      axios
        .get(api_url + "/documents", config)
        .then((response) => {
          this.documents = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          let errors = _.values(error.response.data);
          errors = _.join(errors, "<br/>");
          this.$toastr.e(errors);
        });
    },
    deleteInvoice(document) {
      this.document = document;
      this.$bvModal.show("delete");
    },
    handleDelete() {
      axios
        .delete(api_url + "/documents/" + this.document.id, config)
        .then(() => {
          this.$toastr.s("Facture supprimée");
          this.getDocuments();
        })
        .catch((error) => {
          let errors = _.values(error.response.data);
          errors = _.join(errors, "<br/>");
          this.$toastr.e(errors);
        });
    },
    duplicate(document) {
      this.document = document;
      this.$bvModal.show("duplicate");
    },
    handleDuplicate() {
      axios
        .post(
          api_url + "/documents/" + this.document.id + "/duplicate",
          null,
          config
        )
        .then(() => {
          this.$toastr.s("Facture dupliquée");
          this.getDocuments();
        })
        .catch((error) => {
          let errors = _.values(error.response.data);
          errors = _.join(errors, "<br/>");
          this.$toastr.e(errors);
        });
    },
  },
};
</script>

<style scoped>
.btn-action {
  padding: 5px;
  font-size: 14px;
}
.spinner {
  text-align: center;
  margin: 50px;
}
.document {
  margin-bottom: 10px;
}
</style>
