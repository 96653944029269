/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./bootstrap');

 window.Vue = require('vue').default;

 import vSelect from 'vue-select'
 import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

 import 'bootstrap-vue/dist/bootstrap-vue.css'
 import 'vue-select/dist/vue-select.css';
 import VueToastr from "vue-toastr";
 import VueMoment from "vue-moment"

 Vue.use(BootstrapVue)
 Vue.use(IconsPlugin)
 Vue.use(VueToastr)
 Vue.use(VueMoment);

 /**
  * The following block of code may be used to automatically register your
  * Vue components. It will recursively scan this directory for the Vue
  * components and automatically register them with their "basename".
  *
  * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
  */

 // const files = require.context('./', true, /\.vue$/i)
 // files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

 Vue.component('v-select', vSelect)
 Vue.component('editor', require('./components/editor').default);
 Vue.component('intro', require('./components/intro').default);
 Vue.component('document-index', require('./components/document-index').default);

 require('./filters/number.js');
 require('./filters/date.js');

 /**
  * Next, we will create a fresh Vue application instance and attach it to
  * the page. Then, you may begin adding components to this application
  * or customize the JavaScript scaffolding to fit your unique needs.
  */

 const app = new Vue({
     el: '#app',
 });
