<template>
  <div>
    <div class="navbar-fixed-bottom">
      <button type="button" class="btn btn-success btn-lg" @click="save()">
        Save
      </button>
      <button
        v-if="document.id"
        type="button"
        class="btn btn-secondary btn-lg"
        @click="preview"
      >
        Preview
      </button>
      <button
        v-if="document.id"
        type="button"
        class="btn btn-danger btn-lg"
        @click="download"
      >
        Download
      </button>
      <button
        v-if="document.id"
        type="button"
        class="btn btn-primary btn-lg"
        @click="send"
      >
        Send
      </button>
    </div>

    <button type="button" class="btn btn-success btn-lg" @click="save()">
      Save
    </button>
    <button
      v-if="document.id"
      type="button"
      class="btn btn-secondary btn-lg"
      @click="preview"
    >
      Preview
    </button>
    <button
      v-if="document.id"
      type="button"
      class="btn btn-danger btn-lg"
      @click="download"
    >
      Download
    </button>
    <button
      v-if="document.id"
      type="button"
      class="btn btn-primary btn-lg"
      @click="send"
    >
      Send
    </button>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import ColorPicker from "./colorpicker";
import Logo from "./logo";

const api_url = process.env.MIX_API_URL;
const app_url = process.env.MIX_APP_URL;
const token = localStorage.getItem("token");

let config = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

export default {
  props: ["document"],
  components: {
    ColorPicker,
    Logo,
  },
  data() {
    return {
      currenciesOptions: [],
      api_url,
      app_url,
    };
  },
  mounted() {
    let currencies = require("./currencies");

    _.forEach(currencies, (o) => {
      this.currenciesOptions.push({
        code: o.code,
        label: o.code + " - " + o.label,
      });
    });
    _.sortBy(this.currenciesOptions, ["label"]);
  },
  methods: {
    preview() {
      var url = app_url + "/documents/" + this.document.id;
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    download() {
      var url = api_url + "/documents/" + this.document.id + "/download";
      var a = document.createElement("a");
      a.target = "_blank";
      a.href = url;
      a.click();
    },
    send() {
      var url = app_url + "/documents/" + this.document.id + "/send";
      var a = document.createElement("a");
      a.href = url;
      a.click();
    },
    save() {
      if (this.document.id == undefined) {
        axios
          .post(api_url + "/documents", this.document, config)
          .then((response) => {
            if (response.status == 201) {
              this.$toastr.s("Invoice saved ");
              window.location.href =
                app_url + "/documents/" + response.data.id + "/edit";
            }
          })
          .catch((error) => {
            let errors = _.values(error.response.data);
            errors = _.join(errors, "<br/>");
            this.$toastr.e(errors);
          });
      } else {
        axios
          .put(
            api_url + "/documents/" + this.document.id,
            this.document,
            config
          )
          .then((response) => {
            if (response.status == 200) {
              this.$toastr.s("Invoice updated ");
            }
          })
          .catch((error) => {
            let errors = _.values(error.response.data);
            errors = _.join(errors, "<br/>");
            this.$toastr.e(
              "Une erreur est survenue. Impossible d'enregistrer les modifications."
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.col-form-label {
  font-size: 0.8rem;
}
.form-control {
  font-size: 0.8rem;
}
.navbar-fixed-bottom {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
  background: red;
  padding: 30px;
}
</style>
