// import axios from "axios";

const api_url = process.env.MIX_API_URL;
const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: "Bearer " + token,
    },
};
/*
let API = {
    async post (endpoint, data) {
        await axios
        .post(api_url + endpoint, data, config)
        .then((response) => {
            return response ;
        })
        .catch((error) => {
            console.error("ERROR API ", error.response.data)
          this.$toastr.e("Une erreur est survenue, veuillez réessayer.");
        });
    },
    async get (endpoint) {
        await axios
        .get(api_url + endpoint, config)
        .then((response) => {
            return response ;
        })
        .catch((error) => {
            console.error("ERROR API ", error.response.data)
          this.$toastr.e("Une erreur est survenue, veuillez réessayer.");
        });
    }
}
*/
export default config ;
